import styled from 'styled-components';
import Image from '@next/image';

import { AppLink } from 'components/AppLink/AppLink';
import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledColumns = styled.div`
  padding-top: ${({ theme }) => theme.spacing(7)};

  ${mq.large`
    display: flex;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing(2)};
  `}
`;

export const StyledPhotosColumn = styled.div`
  ${mq.large`
    display: block;
  `}
`;

export const StyledPhotos = styled.div<{ margin?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  margin-left: -400px;

  ${mq.large`
    flex-direction: row;
    height: 800px;
  `}

  ${mq.xxsLarge`
  margin-left: -520px;
  `}

${mq.xxxLarge`
  margin-left: -580px;
  `}

${mq.fourK`
  margin-left: -680px;
  `}
`;

export const StyledTextColumn = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)};

  ${mq.large`
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    max-width: 388px;
  `}
`;

export const StyledTextWrapper = styled.span`
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${mq.large`
    max-width: 388px;
  `}
`;

export const StyledImage = styled(Image)`
  border-radius: ${({ theme }) => theme.borderRadius.m};
  max-width: 70vw;
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  ${mq.large`
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 16px;
    height: auto;
    width: 100%;
    justify-content: flex-start;
  `}
`;

export const StyledLink = styled(AppLink)`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;
