import React, { FC } from 'react';

import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';
import useMedia from 'hooks/useMedia';
import fromCMS from 'utils/fromCMS';

import { GenericProps } from 'components/atoms/Button/GenericButton';
import GeoShapes from './GeoShapes';
import {
  StyledTextWrapper,
  StyledImage,
  StyledPhotos,
  StyledColumns,
  StyledTextColumn,
  StyledPhotosColumn,
  StyledButtonContainer,
  StyledLink,
  StyledContainer
} from './OurArts.styles';

export type OurArtsProps = {
  title: string;
  description: MDXRemoteSerializeResult;
  buttons: Array<{
    textOnButton: string;
    href: string;
    variant: GenericProps['variant'];
  }>;
  image: {
    id: number;
    alt: string;
    path: string;
    file: {
      url: string;
    };
  };
};

export const OurArts: FC<OurArtsProps> = ({ title, description, buttons, image }) => {
  const { isMobile, isSmallMobile } = useMedia();

  return (
    <Container>
      <StyledContainer>
        {!isMobile && <GeoShapes />}
        <StyledColumns>
          <StyledPhotosColumn>
            {!isMobile && (
              <StyledPhotos justifyContent="flex-start">
                <StyledLink key={image.id} href={image.path} title="dribbble project">
                  <StyledImage
                    src={fromCMS(image.file.url)}
                    alt={image.alt}
                    width="1200px"
                    height="600px"
                    quality={100}
                    objectFit="contain"
                  />
                </StyledLink>
              </StyledPhotos>
            )}
          </StyledPhotosColumn>
          <StyledTextColumn>
            <Typography variant="h2" renderAs="h3" weight="700">
              {title}
            </Typography>
            <StyledTextWrapper>
              <Typography variant="body2" weight="400">
                <Marked>{description}</Marked>
              </Typography>
              <StyledButtonContainer>
                {buttons.map((button) => (
                  <LinkedButton
                    href={button.href}
                    key={button.href}
                    width={isSmallMobile ? '100%' : '264px'}
                    variant={button.variant}
                  >
                    {button.textOnButton}
                  </LinkedButton>
                ))}
              </StyledButtonContainer>
            </StyledTextWrapper>
          </StyledTextColumn>
        </StyledColumns>
      </StyledContainer>
    </Container>
  );
};
