import React, { FC } from 'react';

import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import { Shape } from 'components/atoms/Shape/Shape';

const GeoShapes: FC = () => (
  <>
    <Hexagon width="539px" top="190px" right="-320px" variant="backgroundLight" zIndex="-1" />
    <Shape src="/shapes/liki_hex.svg" width={35} height={37} position="absolute" left="1750px" top="780px" />
    <Shape src="/shapes/main_slider_small.png" width={11} height={12} position="absolute" left="1800px" top="720px" />
  </>
);

export default GeoShapes;
